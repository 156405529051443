.container {
    width: full;
    display: flex;
    justify-content: flex-end;
  }
  
  .search-container {
    position: relative;
    display: flex;
    width: 50%;
    margin-top: 10px;
    align-items: center;
    background-color: #ededed;
    border-radius: 25px;
    padding: 0px 7px;
    margin-right: 20px;
    margin-left: auto;
  }
  
  .search-input {
    border: none;
    background: none;
    outline: none;
    margin: 0;
    font-size: 15px;
    flex: 1;
  }
  
  .search-input::placeholder {
    color: #007bff;
    opacity: 1;
    font-size: small;
  }
  
  .search-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .search-button svg {
    fill: #007bff;
    width: 17px;
    height: 17px;
  }