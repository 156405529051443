.header-hero-wrapper {
  background-image: url("../../assets/images/Ellipse1.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 120px 380px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.otherservicefordesktop {
  display: none;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: "Kumbh Sans", sans-serif;
  overflow-x: hidden;
}
.othersericefordesktop {
  display: none;
}
.home {
  max-width: 1500px;
}
@media (min-width: 500px) {
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .otherservicefordesktop {
    display: block;
  }
}
