.referral-form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.referral-form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333333;
}

.referral-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.referral-form-group {
  display: flex;
  flex-direction: column;
}

.referral-form-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #666666;
}

.referral-form-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  transition: border-color 0.2s;
}

.referral-form-input:focus {
  border-color: #007bff;
  outline: none;
}

.referral-form-button {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.referral-form-button:hover {
  background-color: #0056b3;
}
