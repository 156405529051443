.vendor-add-form-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.vendor-add-label {
  display: block;
}

.vendor-add-form-container input {
  border: 1px solid #ccc;
  padding: 8px;
  width: 100%;
}

.vendor-add-vflex-container {
  display: flex;
  gap: 8px;
}

.vendor-add-add-button,
.submit-button,
.vendor-add-cancel-button {
  padding: 10px 16px;
  color: white;
  border: none;
  border-radius: 4px;
}
.image-preview-container {
  margin-top: 10px;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.vendor-add-add-button {
  background-color: #48bb78; /* green-500 */
}

.submit-button {
  background-color: #4299e1; /* blue-500 */
}

.vendor-add-cancel-button {
  background-color: rgba(187, 6, 6, 0.97); /* blue-500 */
}

.vendor-add-disabled-button {
  cursor: not-allowed;
  opacity: 0.5;
}

.vendor-add-button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
