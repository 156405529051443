.cards-container-vendor {
  display: grid;
  grid-template-columns: repeat(2, 2fr) !important;
  justify-items: center;
  padding: 10px;
  align-items: center;
  gap: 5%;
  justify-items: start;
  align-items: stretch;
}

.card-vendor {
  background-color: #e1f0ff;
  padding-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-bottom: 3vh;
}

.vendor-image {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin: auto;
}

.vendor-name {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin: 5px 0 0px;
}

.vendor-role {
  font-size: 10px;
  font-weight: 600;
  color: black;

  margin-bottom: 2px;
}

.vendor-contact {
  font-size: 10px;
  margin-bottom: 0px;
  color: black;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.call-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: -18px;
}

.vendor-reason {
  font-size: 10px;
  background-color: #d9d9d9;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: left;
  color: black;
  padding: 5px;
  width: 100%;
  left: 0px;
  margin: 10px 0px 0 0px;
  font-weight: 700;
}

.vendor-reason strong {
  color: #4c9aff;
  font-size: 16px;
}

.vendor-reason a {
  color: #4c9aff;
  text-decoration: none;
  font-weight: bold;
}

.vendor-reason a:hover {
  text-decoration: underline;
}

/* Responsive Design */

@media (min-width: 1024px) {
  .vendor-image {
    width: 160px;
    height: 160px;
  }

  .vendor-name {
    font-size: 32px;
    font-weight: 800;
  }

  .vendor-role {
    font-size: 24px;
    font-weight: 600;
  }

  .vendor-contact {
    font-size: 20px;

    font-weight: 400;
  }

  .call-icon {
    width: 32px;
    height: 32px;
  }

  .vendor-reason {
    font-size: 24px;
  }

  .vendor-reason strong {
    font-size: 32px;
  }

  .cards-container-vendor {
    column-gap: 10%;
    row-gap: 5%;
  }

  .card-vendor {
    min-width: 40%;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .vendor-image {
    width: 160px;
    height: 160px;
  }

  .vendor-name {
    font-size: 32px;
    font-weight: 800;
  }

  .vendor-role {
    font-size: 24px;
    font-weight: 600;
  }

  .vendor-contact {
    font-size: 20px;

    font-weight: 400;
  }

  .call-icon {
    width: 32px;
    height: 32px;
  }

  .vendor-reason {
    font-size: 24px;
  }

  .vendor-reason strong {
    font-size: 32px;
  }

  .cards-container-vendor {
    column-gap: 10%;
    row-gap: 5%;
  }

  .card-vendor {
    min-width: 40%;
  }
}
