.terms-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.terms-unique-container {
  position: relative;
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  padding-top: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90%;
}

.terms-close-button {
  padding: 0;
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #0c60ca;
}

.terms-close-button:hover {
  color: #ff0000;
}
.terms-unique-title {
  color: #0c60ca;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
}
.terms-unique-heading {
  padding-inline: 0;

  font-size: 18px;
  text-align: center;
  margin-block: 0;
  margin-top: 20px;
}
.termspara {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.terms-unique-list {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.terms-unique-updated {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
