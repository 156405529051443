@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.shops-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 2fr) !important;
  justify-items: stretch;

  align-items: center;
  gap: 5%;
  justify-items: stretch;
  align-items: stretch;
}

.cards {
  background-color: #e1f0ff;
  padding-top: 10px;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  width: 100%;
  opacity: 0; /* Initial state for animation */
  animation: fadeInTop 0.5s forwards; /* Apply animation */
}

.cards.delay-0 {
  animation-delay: 0s;
}

.cards.delay-1 {
  animation-delay: 0.2s;
}

.cards.delay-2 {
  animation-delay: 0.4s;
}

/* Add more delay classes as needed */
.shop-header {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.shop-image-container {
  background-color: white;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-image-ui {
  width: 50x;
  height: 50px;
  object-fit: cover;
}

.shop-status {
  position: absolute;
  top: 10px;
  right: 10px;
}

.status-icon {
  width: 25px;
  margin-bottom: 60px;
  height: 25px;
  margin-right: 5px;
}

.status-text {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.shop-name {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin: 5px 0 0px;
}

.shop-role {
  font-size: 10px;
  font-weight: bold;
  color: black;
  margin-bottom: 2px;
}

.shop-contact {
  font-size: 10px;
  margin-bottom: 0px;
  color: black;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.call-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: -18px;
}

.shop-reason {
  font-size: 10px;
  background-color: #d9d9d9;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: left;
  color: black;
  padding: 5px;
  width: 100%;
  left: 0px;
  margin: 10px 0px 0 0px;
  font-weight: 700;
}

.shop-reason strong {
  font-weight: bold;
  color: #4c9aff;
}

.shop-reason a {
  color: #4c9aff;
  text-decoration: none;
  font-weight: bold;
}

.shop-reason a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (min-width: 500px) and (max-width: 1023px) {
  .cards {
    width: 80%;
  }
  .shop-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 2fr) !important;
    justify-items: stretch;

    align-items: center;
    gap: 5%;
    justify-items: stretch;
    align-items: stretch;
  }
  .status-icon {
    height: 30px;
    width: 30px;
  }
  .call-icon {
    width: 20px;
    height: 20px;
  }
  .shop-image {
    width: 100px;
    height: 100px;
  }

  .shop-name {
    font-size: 24px;
    font-weight: 800;
  }

  .shop-role {
    font-size: 16px;
    font-weight: 600;
  }

  .shop-contact {
    font-size: 18px;

    font-weight: 400;
  }

  .call-icon {
    width: 28px;
    height: 28px;
  }

  .shop-reason {
    font-size: 18px;
  }

  .shop-reason strong {
    font-size: 18px;
  }

  .cards-container {
    column-gap: 10%;
    row-gap: 5%;
  }

  .cards {
    min-width: 40%;
  }
}
@media (min-width: 1024px) {
  .shop-image {
    width: 160px;
    height: 160px;
  }
  .status-icon {
    height: 35px;
    width: 35px;
  }
  .call-icon {
    width: 25px;
    height: 25px;
  }
  .shop-name {
    font-size: 32px;
    font-weight: 800;
  }

  .shop-role {
    font-size: 24px;
    font-weight: 600;
  }

  .shop-contact {
    font-size: 20px;

    font-weight: 400;
  }

  .call-icon {
    width: 32px;
    height: 32px;
  }

  .shop-reason {
    font-size: 18px;
  }

  .shop-reason strong {
    font-size: 32px;
  }

  .shops-cards-container {
    column-gap: 10%;
    row-gap: 5%;
    display: grid;
    grid-template-columns: repeat(2, 2fr) !important;
    justify-items: stretch;

    align-items: center;

    justify-items: stretch;
    align-items: stretch;
  }

  .cards {
    width: 80%;
    min-width: 40%;
  }
}
