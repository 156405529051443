.los-search-container {
  position: relative;
  display: flex;
  width: 60%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  border-radius: 25px;
  padding: 0px 7px;
  margin-inline: 20px;
  height: 40px;
  margin-block: 0;
}

.los-search-input {
  border: none;
  background: none;
  outline: none;
  margin: 0;
  font-size: 15px;
  flex: 1;
}

.los-search-input::placeholder {
  color: #007bff;
  opacity: 1;
  font-size: 12px;
}

.no-results-tooltip {
  position: absolute;
  top: 100%;
  left: 40%;
  transform: translateY(10px);
  background-color: #fff;
  color: #0c60ca;
  padding: 8px 12px;
  border: 1px solid #0c60ca;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.no-results-tooltip::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 20px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: transparent transparent #007bff transparent;
}
.los-search-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.los-search-button img {
  width: 17px;
  height: 17px;
}
@media (min-width: 1024px) {
  .los-search-container {
    width: 50%;
    height: 64px;
    margin-right: 0;
  }
  .los-search-input {
    padding-inline: 20px;
    font-size: 24px;
  }
  .los-search-input::placeholder {
    color: #007bff;
    opacity: 1;
    font-size: 24px;
  }
  .los-search-button img {
    width: 32px;
    height: 32px;
  }
  .no-results-tooltip {
    left: 60%;
  }
}
.los-search-container input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
@media (min-width: 500px) and (max-width: 699px) {
  .los-search-container {
    width: 80%;
    height: 55px;
    margin-right: 0;
  }
  .los-search-input {
    padding-inline: 20px;
    font-size: 24px;
  }
  .los-search-input::placeholder {
    color: #007bff;
    opacity: 1;
    font-size: 24px;
  }
  .los-search-button img {
    width: 32px;
    height: 32px;
  }
  .no-results-tooltip {
    left: 60%;
  }
}
@media (min-width: 700px) and (max-width: 1023px) {
  .los-search-container {
    width: 60%;
    height: 55px;
    margin-right: 0;
  }
  .los-search-input {
    padding-inline: 20px;
    font-size: 24px;
  }
  .los-search-input::placeholder {
    color: #007bff;
    opacity: 1;
    font-size: 24px;
  }
  .los-search-button img {
    width: 32px;
    height: 32px;
  }
  .no-results-tooltip {
    left: 60%;
  }
}
