.voucher-list {
  background: url("../../../assets//images/ProfileScreen/Voucher.svg") no-repeat
    center center;
  background-size: cover;
  /* padding: 20px; */
  border-radius: 12px;
  /* margin: 10px 0; */
  width: 100%;
  text-align: center;

  color: #fff;
}

.no-results-tooltip {
  position: absolute;
  top: 100%;
  left: 40%;
  transform: translateY(10px);
  background-color: #fff;
  color: #007bff;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}
.no-results-tooltip::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 20px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: transparent transparent #007bff transparent;
}
