.los-service-card {
  padding-inline: 20px;
  padding-block: 10px;
  border: 1px solid #cbe2fe;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  gap: 15px;
  align-items: center;
  animation: fadeInTop 0.5s forwards;
}
.los-service-card-text {
  flex: 1;
}
.los-service-card h2 {
  color: #0c60ca;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
}

.los-service-card p {
  color: #1c1717;
  font-weight: 500;
  font-size: 10px;
  text-align: start;
}
.los-service-card button {
  padding: 0;
  background-color: white;
  border: none;
}
.los-service-card button img {
  width: 23px;
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 1024px) {
  .los-service-card h2 {
    font-size: 32px;
  }

  .los-service-card p {
    font-size: 24px;
  }
  .los-service-card button img {
    width: 42px;
  }
  .los-service-card {
    padding-inline: 30px;
    padding-block: 20px;
    margin-block: 20px;
  }
}
