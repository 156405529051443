.heroHeroServicecardsection {
  font-size: 9px;

  max-width: 1200px;
  margin-bottom: 40px;
}

.hero-card-section-wrapper .heroServiceHeader {
  font-weight: 700;
  font-size: 36px;
  color: #0c60ca;
}

.highlightHeroservice {
  color: #df9b28;
  font-weight: bold;
  padding: 2px;
  font-size: medium;
}
.HeroServicecard > img {
  width: 50%;
}
.HeroServicecard {
  cursor: pointer;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
  border: 3px solid #e0eeff;
  opacity: 100;
}

#cst-modal > modal-dailog {
  height: 100%;
  display: flex;
  /* bottom: -8rem; */
  align-items: flex-start;

  padding-block: 30rem;
}

#cst-modal.modal-content {
  width: 100%;
  height: 60%;
  align-items: center;
  border-radius: 0;
  border-radius: 20px;
  padding: 1rem;
  /* bottom: -4rem; */
}
#cst-modal.modal-footer {
  width: 70%;
  /* margin-bottom: -3rem; */
}
.hero-card-section-wrapper .title {
  text-align: left;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #0c60ca;
}
.hero-card-section-wrapper .description {
  font-size: 20px;
  font-weight: 500;

  text-align: left;
}
.hero-card-section-wrapper .seemore {
  font-size: 20px;
  font-weight: 700;
  color: #0c60ca;
  text-decoration: underline;
  text-align: right;
}
@media (min-width: 1024px) {
  .HeroServicecardContainer {
    display: grid;
    grid-template-columns: repeat(2, 2fr) !important;
    justify-items: center;
    padding: 10px;
    align-items: center;
    column-gap: 10%;
    row-gap: 5%;
  }
  .hero-card-section-wrapper .title {
    font-size: 24px;
    font-weight: 700;
    margin-block: 20px;
  }
  .hero-card-section-wrapper .description {
    font-size: 20px;
    font-weight: 500;
  }
  .hero-card-section-wrapper .seemore {
    font-size: 20px;
    font-weight: 700;
  }
}
