.admin-los-card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  position: relative;
}
.los-admin-add-service {
  position: absolute;
  top: -50px;
  right: 10px;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  font-weight: 500;
}

.admin-los-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;

  justify-content: space-between;

  /* width: calc(33.333% - 20px); */
  box-sizing: border-box;
}

.admin-los-card h3 {
  font-size: 15px;
  margin-bottom: 10px;
  color: #333;
}

.admin-los-card p {
  font-size: 12px;
  color: #555;
  margin-bottom: 20px;
}

.admin-los-card-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.admin-los-edit-button,
.admin-los-delete-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-los-edit-button {
  background-color: #007bff;
  color: #fff;
}

.admin-los-edit-button:hover {
  background-color: #0056b3;
}

.admin-los-delete-button {
  background-color: #dc3545;
  color: #fff;
}

.admin-los-delete-button:hover {
  background-color: #c82333;
}
