.emailmodal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.4);  */
  /* Slightly dark background */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-modal {
  background-color: white;

  border-radius: 20px;
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); */
  padding: 20px;
  position: relative;

  margin-top: 0rem;

  animation: slideDown 0.3s ease-out;
}

.email-modal-header {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: flex;
  justify-content: flex-end;
}
.email-option label {
  margin-left: 10px;
  font-size: 14px;
  color: black;
}

.new-email-input {
  margin-top: 10px;
  outline: none;
  border: none;
  background-color: #eaf2fc;
  padding: 8px;

  border-radius: 4px;

  font-size: 14px;
}

/* .email-modal-header h2 {
  font-size: 16px;
  color: black;
  margin: 0;
}

.email-close-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.email-modal-body {
  margin-top: 20px;
}

.email-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}



.add-button {
  background-color: #007bff;
    color: white;
    padding: 6px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    width: 40%;
    margin-top: 20px;
    text-align: center;
}

.add-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} */

.email-close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.email-modal-body {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.email-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-button {
  background-color: #0c60ca;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 40px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;
}

.add-button:hover {
  background-color: #0056b3;
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
