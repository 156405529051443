.shop-container {
  width: 100%;
  margin-top: 8px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.1);
  padding: 4px 16px;
  background-color: white;
}

.shop-content {
  padding: 8px 16px;
  display: flex;
  align-items: flex-start; /* Align items vertically centered */
  justify-content: space-between; /* Ensures proper alignment of info and buttons */
}

.shop-image {
  width: 64px;
  height: 64px;
  border-radius: 8px; /* Optional: Add a bit of rounding to the image corners */
  margin-right: 16px; /* Space between image and text */
}

.shop-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; /* Prevents overflow of content */
}

.shop-name {
  font-weight: bold;
  margin-bottom: 4px;
  white-space: nowrap; /* Prevents text from breaking into multiple lines */
  overflow: hidden; /* Hides overflowed text */
  text-overflow: ellipsis; /* Adds ellipsis for long text */
}

.shop-area,
.shop-type,
.shop-contact {
  color: #4a5568;
  font-size: 1rem;
  margin-bottom: 4px;
}

.shop-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  gap: 16px;
  margin-top: 16px;
  flex-shrink: 0; /* Prevents buttons from shrinking */
}

.delete-button,
.edit-button {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  background-color: #3b82f6;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.delete-button:hover,
.edit-button:hover {
  background-color: #2563eb;
}