.about-us-header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 18px;
  padding-block: 20px;
}
.about-us {
  padding-inline: 18px;
  max-width: 1600px;
}
.who-we-are {
  padding-block: 5px;
}
.blue-heading {
  font-weight: 700;
  font-size: 32px;
  color: #0c60ca;
  line-height: 36px;
}
.orange-heading {
  color: #df9b28;
  line-height: 36px;
}
.paras {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.opacity-reduced-text {
  -webkit-mask-image: linear-gradient(-15deg, transparent -10px, black 66px);
}
.read-full {
  background-color: white;
  color: #0c60ca;
  font-weight: 800;
  font-size: 14px;
  margin-left: -20px;
}

.our-journey {
  margin-top: 25px;
}
.ourjourney-desktop {
  display: none;
}
.leadership {
  margin-top: 25px;
}
.A-container {
  margin-top: 25px;
  margin-left: 20px;
  background-color: #deedff;
  border-radius: 10px;

  display: flex;
  padding: 20px;
}
.A-container-right {
  flex: 1;
}
.A-container-left {
  flex: 2;
}
.A-image {
  margin-left: -30px;
}
.A-read-more {
  display: flex;
  justify-content: flex-end;
}
.A-read-more-button {
  background-color: #0c60ca;
  border-radius: 30px;
  color: white;
  padding-inline: 10px;
  padding-block: 5px;
}
.t-container {
  margin-top: 25px;
  display: flex;
  gap: 10px;
  border: 1px solid #0c60ca;
  border-radius: 10px;
  padding: 15px;
}
.t-container-right {
  flex: 1;

  display: flex;
  align-items: center;
}

.t-container-left {
  flex: 2;
}
.t-container-left-name {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #0c60ca;
  margin-bottom: 10px;
}
.t-container-left-position {
  text-align: center;
  font-size: 9px;
  font-weight: 700;
}
.t-container-left-description {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0;
}

.t-container-right-img {
  width: 110px;
  height: 190px;
}
.team {
  margin-top: 50px;
  padding-block: 5px;
}
.about-us-contact-us {
  margin-top: 40px;
  background-color: #cbdff9;
  margin-inline: -15px;
  padding: 20px;
}
.about-us-contact-us-section {
  display: flex;
  justify-content: space-between;
}
.about-us-contact-us-left {
  font-size: 12px;
  font-weight: 500;
}
.about-us-contact-us-text {
  font-weight: 700;
  font-size: 16px;
  color: #0c60ca;
}
.who-we-are-page-img {
  margin-block: 20px;
  width: 100%;
  opacity: 0;
  animation: fadeIn 4s forwards;
  object-fit: contain;
}
.who-we-are-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.team-image {
  width: 100%;
  opacity: 0;
  animation: fadeIn 4s forwards;
  object-fit: contain;
  padding-inline: 10px;
}
.desktop-version {
  display: none;
}
.team-heading {
  font-weight: 700;
  font-size: 20px;
  color: #0c60ca;
  line-height: 15px;
  text-align: center;
  margin-bottom: 0;
}
.about-us-contact-us-right img {
  width: 60px;
  height: 60px;
}
@media (min-width: 1024px) {
  .who-we-are {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .who-we-are-text {
    flex: 3;
  }
  .who-we-are-img {
    flex: 2;
  }
  .about-us {
    padding-inline: 100px;
  }
  .blue-heading {
    font-weight: 700;
    font-size: 48px;
    color: #0c60ca;
    line-height: 55px;
  }
  .orange-heading {
    color: #df9b28;
    line-height: 48px;
  }
  .paras {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    line-height: 32px;
  }
  .who-we-are-page-img {
    max-width: 500px;

    margin: 20px;
  }
  .our-journey {
    display: none;
  }
  .ourjourney-desktop {
    display: block;
  }
  .A-container-left {
    display: none;
  }
  .a-container-wrapper {
    display: flex;
    gap: 80px;
    padding-block: 20px;
  }
  .a-container-wrapper > * {
    flex: 1;
  }
  .desktop-version {
    display: block;
  }

  .not-for-desktop {
    display: none;
  }
  .A-image {
    width: 300px;
    height: 300px;
  }
  .t-container-left-name {
    font-size: 40px;
    text-align: center;
  }
  .t-container-left-position {
    font-size: 16px;
    text-align: center;
  }
  .t-container-left-description {
    font-size: 24px;
  }
  .team-heading {
    margin-top: 20px;
    font-size: 48px;
  }
  .about-us-contact-us {
    margin-top: 40px;
    background-color: #cbdff9;
    margin-inline: -15px;
    padding: 20px;
  }

  .about-us-contact-us-left {
    font-size: 24px;
    font-weight: 500;
  }
  .about-us-contact-us-text {
    font-weight: 700;
    font-size: 32px;
    color: #0c60ca;
  }
  .about-us-contact-us-right img {
    width: 180px;
    height: 180px;
  }
  .about-us-contact-us {
    padding: 40px;
  }
  .team-image {
    width: 80%;
    opacity: 0;
    animation: fadeIn 4s forwards;
    object-fit: contain;
    padding-inline: 10px;
  }
  .A-read-more {
    display: none;
  }
}
