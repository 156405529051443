.footer {
  background-color: #f8f9fa;
  color: #333;
  width: 100vw;
}

.footer-content {
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  align-items: center;
  background-color: #eaf2fc;
  font-family: "Kumbh Sans";
  font-size: 12px;
  font-weight: 600;
}

.footer-logo img {
  height: 65px;
  margin-right: 50px;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.footer-nav li {
  margin: 5px 0;
}

.footer-copyright {
  text-align: end;
  padding: 5;
  margin: 10px;
  margin-top: 10px;
}
@media (min-width: 1024px) {
  .footer-logo img {
    height: 280px;
    margin-right: 50px;
  }
  .footer-content {
    font-size: 32px;
    font-weight: 700;
    margin-top: 50px;
  }
}
