.no-emergency-text {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 40px;
    height: 100%; 
    text-align: center; 
}

.emergency-page{
    max-width: 1024px;
}
