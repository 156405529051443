/* Styles for the Diwali Offer Pop-up */
.diwali-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.diwali-popup-container {
  margin-inline: 20px;
  position: relative;
  background-color: white;
  width: 400px;
  padding-block: 20px;
  border-radius: 20px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  text-align: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(84, 7, 112, 1) 0,
    rgba(6, 47, 100, 1) 100%
  );
}

.diwali-popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.diwali-popup-img {
  width: 100%;
  height: auto;
  margin-block: 15px;
}

.diwali-popup-text {
  font-size: 16px;
  font-weight: 600;
  padding-inline: 30px;
  margin-bottom: 20px;
  color: #df9b28;
}

.diwali-popup-button {
  background-color: #0c60ca;
  color: white;
  border: none;
  padding: 10px 40px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s;
}

/* .diwali-popup-button:hover {
  background-color: #ff7733;
} */
