.cluster-container-admin {
  width: 100%;
  margin-top: 8px; /* Equivalent to mt-2 */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-lg */
  padding: 4px 16px; /* Equivalent to px-4 py-1 */
  background-color: white;
}

.cluster-content-admin {
  padding: 8px 16px; /* Equivalent to px-2 py-2 */
  display: flex;
}

.cluster-info-admin {
  flex: 1;
}

.cluster-name-admin {
  font-weight: bold; /* Equivalent to font-bold */
  margin-bottom: 4px; /* Equivalent to mb-1 */
}

.cluster-area-admin,
.cluster-buildings-admin {
  color: #4a5568; /* Equivalent to text-gray-700 */
  font-size: 1rem; /* Equivalent to text-base */
  margin-bottom: 4px; /* Equivalent to mb-1 */
}

.cluster-buttons-admin {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  gap: 16px; /* Equivalent to space-x-4 */
  margin-top: 16px; /* Equivalent to mt-4 */
}

.delete-button-admin,
.edit-button-admin {
  display: flex;
  align-items: center;
  gap: 16px; /* Equivalent to gap-4 */
  padding: 8px 16px; /* Equivalent to px-4 py-2 */
  background-color: #3b82f6; /* Equivalent to bg-blue-500 */
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.add-cluster-button-admin {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  color: white;
  border-radius: 4px;
  background-color: #407cff;
  border: none;
  cursor: pointer;
}

.add-cluster-button-admin:hover {
  background-color: #1d4ed8; /* Equivalent to Tailwind's bg-blue-700 */
}
