.search-container {
  position: relative;
  display: flex;
  width: 50%;
  margin-top: 10px;
  align-items: center;
  background-color: #ededed;
  border-radius: 25px;
  padding: 0px 7px;
  margin-right: 30px;
  margin-left: auto;
}

.search-input {
  border: none;
  background: none;
  outline: none;
  margin: 0;
  font-size: 15px;
  flex: 1;
}

.search-input::placeholder {
  color: #007bff;
  opacity: 1;
  font-size: small;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.search-button svg {
  fill: #007bff;
  width: 17px;
  height: 17px;
}

@media (min-width: 1024px){
  .search-container{
    width: 50%;
    height: 64px;
    margin-right: 0;
  }
  .search-input {
    padding-inline: 20px;
    font-size: 24px;
  }
  .search-input::placeholder {
    color: #007bff;
    opacity: 1;
    font-size: 24px;
  }
  .search-button svg {
    width: 32px;
    height: 32px;
  }
}
