.los-subservice {
  padding-inline: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  padding-bottom: 40px;
}
.los-subservice-top {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}
.los-subservice-to-h1 {
  flex: 1;
}
.los-subservice-top-descri {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-inline: 40px;
}
.los-book-now {
  background-color: #0c60ca !important;
  border-radius: 25px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding-inline: 30px;
  padding-block: 10px;
}

@media (min-width: 1024px) {
  .los-subservice {
    padding-block: 40px;
  }
  .los-subservice-top-descri {
    font-size: 32px;
    line-height: 40px;
  }
  .grid-for-desktop {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr) !important;
    justify-items: center;
    padding: 10px;
    align-items: center;
    column-gap: 10%;
    row-gap: 5%;
    justify-items: start;
    align-items: stretch;
  }
  .los-book-now {
    font-size: 24px;
    margin-top: 40px;
    padding-inline: 60px;
    padding-block: 10px;
  }
}
