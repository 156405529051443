.redemption-requests-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.redemption-requests-card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 40px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin: 10px;
}

.redemption-requests-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.redemption-requests-container p {
  margin: 8px 0;
  font-size: 16px;
}

.redemption-requests-email-button {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}

.redemption-requests-email-button:hover {
  background-color: #0056b3;
}
