.modal-overlay-vendorModal {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-vendorModal {
  background-color: white;
  border-radius: 15px;
  width: 70%;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.modal-overlay-vendorModal .close-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.modal-body {
  text-align: center;
}

.selected-vendor-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.vendor-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.vendor-profession {
  font-weight: 500;
  color: black;
  font-family: "Kumbh Sans";
  margin-bottom: 5px;
}

.vendor-phone {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin-bottom: 10px;
}

.vendor-phone a {
  text-decoration: none;
  color: inherit;
}

.reason-container {
  text-align: left;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #eaf2fc;
  background-color: #eaf2fc;
}

/* Media Queries */

/* Phones */
@media (max-width: 600px) {
  .modal-content-vendorModal {
    max-width: 90%;
  }

  .vendor-name {
    font-size: 15px;
    font-weight: 600;
  }

  .vendor-profession {
    font-size: 12px;
    font-weight: 600;
  }

  .vendor-phone {
    font-size: 12px;
  }

  .reason-container {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
  }
}

/* Tablets */
@media (min-width: 500px) and (max-width: 1023px) {
  .modal-content-vendorModal {
    max-width: 60%;
  }

  .vendor-name {
    font-size: 24px;
  }

  .vendor-profession {
    font-size: 18px;
  }

  .vendor-phone {
    font-size: 18px;
  }

  .reason-container {
    font-size: 16px;
  }
  .modal-overlay-vendorModal .close-icon {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1024px) {
  .modal-overlay-vendorModal {
    display: none;
  }
}
