.los-subservice-card {
  padding-block: 30px;
  padding-inline: 20px;
  border: 1px solid #e0eeff;
  border-radius: 20px;
  margin-block: 20px;
  width: 100%;
  animation: fadeInTop 0.5s forwards;
}
.subservice-name {
  font-weight: 700;
  font-size: 16px;
  color: #0c60ca;
  padding: 0;
}
.los-subservice-card .subservices {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.subservice-detail {
  display: grid;
  grid-template-columns: 40% 60%;
  margin-top: 10px;
}
.subservice-detail .name {
  font-size: 14px;
  font-weight: 700;
  word-wrap: break-word !important;
  width: 100px;
  text-align: left;
}
.subservice-detail .value {
  font-size: 14px;
  font-weight: 400;

  text-align: left;
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 1024px) {
  .subservice-name {
    font-size: 32px;
  }
  .subservice-detail .name {
    font-size: 24px;
  }
  .subservice-detail .value {
    font-size: 24px;
  }
  .los-subservice-card {
    min-width: 40%;
  }

  .subservice-detail .name {
    width: auto;
  }
}
