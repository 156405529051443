.ContactUs-h2 {
  color: #0c60ca;
  font-weight: 700;
  margin-top: -15vh;
  width: 100%;
  font-size: 24px;
  padding-inline: 0px;
}

.contactUs ul {
  padding-inline: 10px;
  width: 330px;
}

.contactUs button {
  font-size: 12px;
  font-weight: 600;
  background-color: #0c60ca;
  border-radius: 40px;
  color: white;
  border: none;
  cursor: pointer;
}

.contactUs ul li {
  padding-right: 30px;
  font-size: 12px;
  font-weight: 500;
}

#contactUs {
  position: relative;
  width: 100vw;
  max-width: 1500px;

  background: url("/src/assets/images/Group1.png") no-repeat right bottom;
  background-size: 30vw 65vh;
  height: 60vh;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contactUs-content {
  padding: 10px;
  border-radius: 10px;
}
a {
  text-decoration: none;
}
@media (min-width: 1024px) {
  #contactUs {
    margin-top: 0;
    background-size: 18vw 120vh;
    height: 120vh;
    margin-block: -150px;
    justify-content: flex-start;
    padding-left: 100px;
  }
  .ContactUs-h2 {
    font-size: 64px;
  }
  .contactUs ul li {
    font-size: 24px;
  }
  .contactUs ul {
    width: 600px;
  }
  .contactUs button {
    font-size: 24px;
    padding-inline: 50px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #contactUs {
    margin-top: 0;
    background-size: 30vw 90vh;
    height: 120vh;
    margin-block: -300px;
    justify-content: flex-start;
  }
  .ContactUs-h2 {
    font-size: 50px;
  }
  .contactUs ul li {
    font-size: 18px;
  }
  .contactUs ul {
    width: 100%;
  }
  .contactUs button {
    font-size: 18px;
    padding-inline: 50px;
  }
}
