.emptyvouchermodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.emptyvouchermodal-content {
  background-color: white;
  border-radius: 10px;
  width: 256px;

  margin-top: -7rem;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}
.emptyvouchermodal-header {
  margin-top: -2rem;
}
.emptyvouchermodal-close {
  padding: 12px;
  font-size: 44px;
  margin-left: 12rem;
  color: cornflowerblue;
}
.emptyvouchermodal-body {
  margin-top: 1rem;
}
.modal-close-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
