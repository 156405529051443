.edit-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.edit-form-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.edit-form-label {
  display: block;
  margin-bottom: 15px;
  color: #555;
}

.edit-form-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.subservice-title {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

.subservice-container {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.detail-container {
  margin-bottom: 10px;
}

.add-detail-button,
.delete-subservice-button,
.add-subservice-button,
.save-changes-button,
.cancel-button {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-detail-button {
  background-color: #6c757d;
  color: #fff;
}

.delete-subservice-button {
  background-color: #dc3545;
  color: #fff;
}

.add-subservice-button {
  background-color: #007bff;
  color: #fff;
}

.save-changes-button {
  background-color: #28a745;
  color: #fff;
}

.cancel-button {
  background-color: #ffc107;
  color: #fff;
}
