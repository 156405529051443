.otherServices-container {
  /* margin-top: -300px; */
  position: relative;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otherServices-container .line {
  width: 200px;
  margin-inline: 80px;
  border-top: 3px solid #0c60ca;
  margin-block: 50px;
}

.services {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.serviceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 50px;
  height: 50px;
}

.serviceText {
  font-size: 10px;
  font-weight: 700;
  color: #0c60ca;
  font-family: "kumbh Sans";
}

a {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .icon {
    width: 120px;
    height: 120px;
  }
  .serviceText {
    font-size: 24px;
  }
  .otherServices-container .line {
    width: 70vw;
    max-width: 1500px;
    margin-inline: 80px;
    border-top: 3px solid #0c60ca;
    margin-block: 50px;
  }
  .services {
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .icon {
    width: 90px;
    height: 90px;
  }
  .serviceText {
    font-size: 18px;
  }
  .otherServices-container .line {
    width: 60vw;
    max-width: 1500px;
    margin-inline: 80px;
    border-top: 3px solid #0c60ca;
    margin-block: 20px;
  }
  .services {
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
  }
}
