.los-type-page {
  max-width: 1200px;
  padding-bottom: 40px;
}
.Los-types-img {
  padding-inline: 1rem;
  padding-bottom: 4px;
}

.Los-types-img img {
  width: 100%;
  opacity: 0;
  animation: fadeIn 4s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 500px) and (max-width: 1023px) {
  .los-type-page {
    padding: 30px;
  }
}
