.los-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 2fr) !important;
  justify-items: center;
  padding: 10px;
  align-items: center;
  gap: 5%;
  justify-items: start;
  align-items: stretch;
}

.los-card {
  background-color: #e1f0ff;
  padding-block: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-bottom: 3vh;
}

.los-type-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.los-type-name {
  font-size: 12px;
  font-weight: 800;
  color: black;
  margin: 5px 0 0px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.los-type-descri {
  font-size: 10px;
  font-weight: 500;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  text-align: center;
  color: black;
  padding: 5px;
  width: 100%;
  left: 0px;
  margin: 10px 0px 0 0px;
}
.los-cards-container button {
  background-color: #e1f0ff;
  border: none;
  padding: 0;
}
.los-cards-container button img {
  width: 23px;
}

/* Responsive Design */
@media (min-width: 1024px) {
  .los-cards-container {
    column-gap: 10%;
    row-gap: 5%;
  }

  .los-card {
    min-width: 40%;
  }
  .los-type-image {
    width: 161px;
    height: 161px;
    object-fit: cover;
  }

  .los-type-name {
    font-size: 32px;
  }

  .los-type-descri {
    font-size: 24px;
  }

  .los-cards-container button img {
    width: 48px;
  }
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 650px) and (max-width: 1023px) {
  .los-cards-container {
    column-gap: 10%;
    row-gap: 5%;
  }

  .los-card {
    padding: 20px;
    min-width: 40%;
  }
  .los-type-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  .los-type-name {
    font-size: 24px;
  }

  .los-type-descri {
    font-size: 18px;
  }

  .los-cards-container button img {
    width: 35px;
  }
}
