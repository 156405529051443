.vendor-blacklist-page {
  max-width: 1200px;
  padding-bottom: 40px;
}
.vendor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.header-title {
  font-weight: 700;
  color: #0c60ca;
  font-size: 20px;
  margin: 0;
}

.no-result-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 18px;
  color: #888;
  text-align: center;
}

.header-icon {
  width: 24px;
  height: 24px;
}
.wrapper {
  width: 100vw;
}
@media (min-width: 1024px) {
  .wrapper {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaf2fc55;
  }
  .wrapper > * {
    background-color: white;
  }
  .header-icon {
    width: 44px;
    height: 44px;
  }
  .header-title {
    font-size: 48px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .vendor-blacklist-page {
    padding: 40px;
  }
  .header-title {
    font-size: 35px;
  }
  .header-icon {
    width: 35px;
    height: 35px;
  }
}
