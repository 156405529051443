.modal-overlay-hero-whatsapp {
  z-index: 200;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  padding-bottom: 20px;
}

.join-ilaqa-modal-content-hero-whatsapp {
  background-color: white !important;
  border-radius: 20px !important;
  z-index: 50;
  border-radius: 10px;
  padding: 20px;
  padding-block: 50px;

  max-width: 500px;
  position: relative;
}
.whatsapp-link-hero-whatsapp {
  margin-top: 10px;
  text-align: center;
}
.whatsapp-modal-heading-hero-whatsapp {
  font-size: 15px;
}
.whatsapp-link-hero-whatsapp a {
  color: #0c60ca;
  text-decoration: underline;
}
.close-btn-whatsapp-hero-whatsapp {
  position: absolute;
  top: 17px;
  right: 10px;
  cursor: pointer;
}
.close-btn-whatsapp-hero-whatsapp img {
  height: 24px;
}

.modal-content-hero-whatsapp h2 {
  font-weight: 600;
  font-size: 14px;
  color: #0c60ca;
}

.modal-content-hero-whatsapp h2 span {
  color: red;
}

.modal-overlay-hero-whatsapp form {
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
}

.modal-overlay-hero-whatsapp label {
  margin-bottom: 10px;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  color: #0c60ca;
}

.modal-overlay-hero-whatsapp input,
.modal-overlay-hero-whatsapp select {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  min-width: 10%;
  margin-bottom: 15px;
  background-color: #eaf2fc;
  border-radius: 10px !important;
  box-sizing: border-box;
  border: none;
}
.modal-overlay-hero-whatsapp select:focus {
  outline: none;
  border: none;
}
.modal-overlay-hero-whatsapp input:focus {
  outline: none;
}
.phone-code {
  padding-left: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #eaf2fc;
}
.phone-code input {
  margin: 0;
}
.modal-content select option {
  background-color: #fff;
  color: gray;
}
.whtsapp-btn-wrapper-hero-whatsapp {
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}
.whatsapp-btn-hero-whatsapp {
  background-color: #0c60ca;
  padding-inline: 10px;
  color: white;
  padding: 10px;

  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
.disable-hero-whatsapp {
  background-color: #0c60ca66;
  cursor: not-allowed;
}
.whatsapp-btn-hero-whatsapp img {
  height: 20px;
}

.whatsapp-btn-hero-whatsapp span {
  font-size: 12px;
}
.whatsapp-btn {
  background-color: #0c60ca;
  color: white;
  padding-inline: 15px;
  padding-block: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
}
.modal-whatsapp-icon {
  width: 20px;
  height: 20px;
}
@media (min-width: 1024px) {
  .join-ilaqa-modal-content-hero-whatsapp {
    max-width: 60vw;
  }
  /* .join-ilaqa-modal-content-hero-whatsapp form {
    padding: 20px;
  } */
  .whatsapp-btn-hero-whatsapp img {
    height: 20px;
  }

  .whatsapp-btn-hero-whatsapp span {
    font-size: 12px;
  }
  .whatsapp-modal-heading-hero-whatsapp {
    margin-block: 10px;
    font-size: 36px;
  }
  .whatsapp-link-hero-whatsapp a {
    color: #0c60ca;
    text-decoration: underline;
  }
  .close-btn-whatsapp-hero-whatsapp {
    position: absolute;
    top: 17px;
    right: 10px;
    cursor: pointer;
  }
  .close-btn-whatsapp-hero-whatsapp img {
    height: 35px;
    width: 35px;
  }

  .modal-content-hero-whatsapp h2 {
    font-weight: 600;
    font-size: 14px;
    color: #0c60ca;
  }

  .modal-content-hero-whatsapp h2 span {
    color: red;
  }

  .modal-overlay-hero-whatsapp form {
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
  }

  .modal-overlay-hero-whatsapp label {
    margin-bottom: 30px;
    text-align: left;
    font-size: 30px;
    font-weight: 700;
    color: #0c60ca;
  }

  .modal-overlay-hero-whatsapp input,
  .modal-overlay-hero-whatsapp select {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    min-width: 10%;
    margin-bottom: 8px;
    background-color: #eaf2fc;
    border-radius: 10px !important;
    box-sizing: border-box;
    border: none;
  }
  .modal-overlay-hero-whatsapp select:focus {
    outline: none;
    border: none;
  }
  .modal-overlay-hero-whatsapp input:focus {
    outline: none;
  }
  .phone-code {
    padding-left: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #eaf2fc;
  }
  .phone-code input {
    margin: 0;
  }
  .modal-content select option {
    background-color: #fff;
    color: gray;
  }
  .whtsapp-btn-wrapper-hero-whatsapp {
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
  }
  .whatsapp-btn-hero-whatsapp {
    background-color: #0c60ca;
    padding-inline: 10px;
    color: white;
    padding: 10px;

    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
  .whatsapp-btn {
    font-size: 30px;
    padding-inline: 25px;
    padding-block: 20px;
    gap: 15px;
    border-radius: 20px;
  }
  .modal-whatsapp-icon {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .los-type-page {
    padding: 30px;
  }
}
