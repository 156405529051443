.form-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label {
  display: block;
}

.select,
.input {
  border: 1px solid #ccc;
  padding: 8px;
  width: 100%;
}

.flex-container {
  display: flex;
  gap: 8px;
}

.add-button,
.submit-button,
.cancel-button-cluster {
  padding: 10px 16px;
  color: white;
  border: none;
  border-radius: 4px;
}

.add-button {
  background-color: #48bb78; /* green-500 */
}

.submit-button {
  background-color: #4299e1; /* blue-500 */
}

.cancel-button-cluster {
  background-color: #4299e1; /* blue-500 */
}

.disabled-button {
  cursor: not-allowed;
  opacity: 0.5;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
