.referral-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  position: relative;
}

.referral-card {
  background-color: #007bff11;
  border: 1px solid #ddd;
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.referral-card:hover {
  transform: translateY(-5px);
}

.referral-card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
  color: #007bff;
}

.referral-card p {
  margin: 4px 0;
  font-size: 0.9rem;
}

.approve-button {
  margin-top: 12px;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease-in-out;
}

.approve-button:hover {
  background-color: #218838;
}

.approve-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
.addReferralButton {
  position: absolute;
  top: -50px;
  right: 10px;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  font-weight: 500;
  border-radius: 40px;
}
