.profile-card {
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
  max-width: 1024px;
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.avatar {
  width: 140px;
  height: 140px;
  background: url("../../assets/images/ProfileScreen/profilepic.svg")
    center/cover no-repeat;
}

.greeting {
  flex: 1;
  margin-left: 10px;
}

.greeting span {
  color: #2b87d1;

  font-weight: 600;
  font-size: 16px;
}

.greeting h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #2b87d1;
}

.edit-icon {
  /* font-size: 24px;
     cursor: pointer; */
  width: 25px;
  cursor: pointer;
}

.profile-info {
  margin-bottom: 20px;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.info-item h4 {
  margin: 0;
  font-size: small;
  color: #888;
}

.info-item p {
  margin: 5px 0 15px;
  color: #333;
  text-align: left;
  font-size: smaller;
  font-weight: bold;
}

.profile-info .info-item hr {
  border: none;
  border-top: 2px solid #2b87d1;
  margin: 8px 0;
}

.logout {
  color: #2b87d1;
  cursor: pointer;
  text-align: right;
  margin-bottom: 20px;

  margin-bottom: 16px;
  background: none;
  box-shadow: none;
  font-weight: bold;
  border-radius: 0;
  font-size: 16px;
}

.referral-section {
  padding: 15px;
  border-radius: 10px;
  background: url("../../assets/images/ProfileScreen/ReferralCard.svg")
    center/cover no-repeat;
  box-shadow: 0 0 10px rgba(185, 97, 159, 0.1);
}

.referral-header span {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  width: 108%;
  justify-content: left;
  font-weight: 600;
  font-size: medium;
}

.referral-header img {
  margin: 8px;
}

.progress-bar {
  background: #f8f9fa;
  height: 10px;
  border-radius: 10px;
  margin-bottom: 40px;
  margin-top: 4px;
  overflow: hidden;
}

.progress-section.green {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Text color inside progress */
  font-weight: bold;
  font-size: 14px;
  transition: width 0.5s ease;
}

/* .thumb {
    width: 20px;
    height: 20px;
    background-color: #ff9800; 
    border-radius: 50%;
    position: absolute;
    top: -5px;
    transform: translateX(-50%);
  } */
.bonus-details-wrapper {
  border-radius: 10px;
  background-color: white;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.bonus-details {
  display: flex;
  gap: 5px;

  position: relative;
}

.bonus-details h3 {
  margin: 0 0 5px;
  font-size: small;
  font-weight: bold;
  color: black;
}

.bonus-details p {
  text-align: left;

  font-size: 10px;
  position: relative;
}

.redeem-btn {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #bbccdd;
  /* color: orange; */
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  font-size: small;
  /* position: fixed; */
  margin-top: -2rem;
  right: 54px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-email {
  width: 50%;
  font-size: 12px;
  font-weight: bold;
  color: #2b87d1;
  cursor: pointer;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
  /* position: fixed;
  top: 557px;
  left: 43px;
  right: 46px; */
  font-size: 10px;
  color: #000;
}

.bonus-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bonus-amount span {
  font-size: 10px;
  color: #1976d2;
  font-weight: bold;
}

.sparkle-image {
  width: 50px; /* Adjust the size of the sparkle image */
  height: 50px;
  animation: sparkle 2s infinite; /* You can adjust the duration */
}

@keyframes sparkle {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) rotate(45deg);
    opacity: 0.8;
  }
}

.thumb-images {
  display: flex;
  align-items: center;
}

.sparkle-thumb {
  width: 200px;
  height: 200px;
  transition: left 0.5s ease;
}

.celebration-image {
  width: 262px;
  height: 225px;
  margin-left: -86px;
  margin-top: 31px;
  transition: left 0.5s ease;
  /* margin-left: 10px; */
}
