/* Header styles */
header {
  width: 100vw;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px;
  padding-block: 10px;
  z-index: 10;
  height: 90px;
  position: relative;
  max-height: 115px;
}
.no-scroll {
  overflow: hidden;
}
.hello-user-nav {
  font-size: 10px;
  color: #0c60ca;
  font-weight: 400;
}
.logo {
  flex-grow: 1;
}
.logo img {
  height: 61px;
  cursor: pointer;
}

.hamburger,
.profile-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.nav {
  position: fixed;
  top: 0;
  top: 115px;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 10;
}

.nav.open {
  transform: translateX(0);
}

.nav.close {
  transform: translateX(100%);
}
.nav ul {
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav li {
  margin: 0 15px;
}

.nav a {
  text-decoration: none;
  color: gray;
  font-weight: 700;

  display: none;
}

.menu-item {
  position: relative;
  display: flex;
  align-items: center;
}

.menu-item:not(.no-arrow)::after {
  content: ">";
  position: absolute;
  right: 10px;
  font-size: 16px;
  color: #0c60ca;
}
.menu-item.active {
  color: #df9b28;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.nav ul {
  display: block;
  margin: 0;
  padding: 0;
}

.nav li {
  margin: 0;
  border-bottom: 2px solid #dcebf4;
  padding: 5px 0;
}

.nav a {
  display: block;
  padding: 10px;
  font-family: "Kumbh Sans";
  font-size: 16px;
  font-weight: 700;
}

.nav .my-ilaqa {
  color: #0c60ca;
  font-weight: 900;
}

.gradient-text {
  background: linear-gradient(#f0b70c 0, #e9b33c 40%, #dd3822 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ilaqa-tagline-section {
  display: none;
}
.right-nav {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.aboutus-btn {
  display: none;
}
.login-btn-header {
  font-size: 12px;
  padding-block: 2px;
  padding-inline: 10px;
}
.drive-icon {
  width: 40px;
  height: 30px;
}
/* desktop version styles */
@media (min-width: 1024px) {
  header {
    height: 120px;
  }
  .hello-user-nav {
    font-size: 12px;
  }
  .drive-icon {
    width: 50px;
    height: 40px;
  }
  .login-btn-header {
    font-size: 20px;
    padding-block: 2px;
    padding-inline: 30px;
  }

  header {
    padding-inline: 80px;
    padding-block: 10px;
    border-bottom: 2px solid #cde3ff;
  }
  .logo img {
    height: 90px;
    width: 90px;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .ilaqa-tagline-section {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .ilaqa-section {
    font-size: 64px;
    font-weight: 700;
    color: #0c60ca;
    line-height: 68px;
  }
  .ilaqa-tagline {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }

  .profile-icon {
    width: 36px;
    height: 36px;
  }
  .hamburger {
    width: 36px;
    height: 36px;
  }
  .menuclose {
    width: 30px;
    height: 30px;
  }
  .nav {
    width: 50%;
    background-color: #f5f5f5;
  }
  .aboutus-btn {
    display: block;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .logo img {
    height: 75px;
    width: 75px;
  }
  .profile-icon {
    width: 33px;
    height: 33px;
  }
  .hamburger {
    width: 33px;
    height: 33px;
  }
  .menuclose {
    width: 28px;
    height: 28px;
  }
  .nav {
    width: 50%;
    background-color: #f5f5f5;
  }
  .aboutus-btn {
    display: block;
  }
  header {
    padding-inline: 40px;
    padding-block: 10px;
    border-bottom: 2px solid #cde3ff;
  }
}
