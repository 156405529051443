.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Kumbh Sans", sans-serif;
  padding-inline: 20px;
  z-index: 1;
  width: 100vw;
  max-width: 1500px;
  overflow-x: hidden;

  background-image: url("../../assets/images/Ellipse 45.png"),
    url("../../assets/images/Ellipse 44.png");
  background-position: right top, left bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 45px 180px, 84px 264px;
}

.hero .hero-text {
  padding-inline: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hero-text h1 {
  font-size: 48px;
  color: var(--primary-color);
  font-weight: 700;
  padding-left: 0;
}

/* .hero-text p {
  font-size: 16px;
  font-weight: 500;
} */

.hero-text ul {
  padding-inline: 20px;
}

.hero-text .ilaqa {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: var(--primary-color);
  margin: 0;
}
.ilaqa-wrapper {
  margin-top: -5px;
}
.ilaqa-tag {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

.hero-text .about-ilaqa {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
}

.hero-text ul li {
  font-size: 12px;
  font-weight: 500;
}

.main-container .btn-column {
  display: flex;
  flex-direction: column;
}

.heroListButton {
  color: var(--primary-color);
  background: #fff;
  border: 2px solid var(--primary-color);
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  font-family: "Kumbh Sans", sans-serif;
  border-radius: 50px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 10px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 20px;
}

.heroListButton:hover {
  background: var(--primary-color);
  color: #fff;
}

.hero-heading {
  padding-inline: 0px;
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 600;
}

.one-stop {
  color: var(--secondary-color);
}
.desktop-hero-buttons {
  display: none;
}

.joinButton {
  color: #fff;
  background: var(--primary-color);
  border: none;
  padding: 15px 30px;

  cursor: pointer;
  font-family: "Kumbh Sans", sans-serif;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  margin: 10px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 20px;
}

.joinButton:hover {
  background: var(--primary-color);
}

.service-container {
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 800px;
}

.hero-text h2.heroHeading {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 30px;
}
.hero-right-image {
  display: none;
}
.List-of-Services-before-btn {
  color: #0c60ca;
  font-weight: 600;
  font-size: 18px;
  flex: 2;
  padding-right: 2.5rem;
  display: flex;
  justify-content: flex-end;
}
.Welcome-to-your-iLaqa-before-btn {
  color: #0c60ca;
  font-weight: 600;
  font-size: 18px;
  flex: 2;
  width: 285px;
  padding-right: 2.5rem;
  text-align: right;
}
.whatsapp {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
/* Responsive styles for mobile devices */
@media (min-width: 1024px) {
  .desktop-hero-buttons {
    display: block;
  }
  .hero {
    background-image: none;
  }
  .heroListButton {
    font-size: 24px;
    font-weight: 700;
    padding-inline: 30px;
  }
  .joinButton {
    font-size: 24px;
    font-weight: 700;
    padding-inline: 30px;
  }
  .whatsapp {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
  .parentdiv {
    display: none;
  }
  .hero-text-with-image {
    display: flex;
    flex-direction: row;

    width: 100vw;
    max-width: 1500px;
  }
  .hero-text {
    padding-left: 100px;
    flex: 1;
  }
  .hero-right-image {
    display: block;
    width: 600px;
    height: 600px;
    margin-right: -80px;
    flex: 1;
  }
  .ilaqa {
    display: none;
  }
  .hero-heading {
    font-size: 48px;
    font-weight: 600;
  }
  .hero-text ul li {
    font-weight: 500;
    font-size: 24px;
  }
  .hero-text-wrapper {
    width: 100%;
    padding-left: 100px;
  }
  .hero {
    overflow-x: hidden;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .hero {
    padding-top: 50px;
  }
  .hero-text .ilaqa {
    font-size: 36px;
  }
  .hero-heading {
    font-size: 36px;
    font-weight: 600;

    margin-bottom: 20px;
  }
  .hero-text ul li {
    font-weight: 500;
    font-size: 18px;
  }
  .joinButton {
    font-size: 18px;
  }
  .List-of-Services-before-btn {
    font-size: 24px;

    padding-right: 2.5rem;
  }
  .Welcome-to-your-iLaqa-before-btn {
    font-size: 24px;
    flex: 2;
    width: auto;
    padding-right: 2.5rem;
    text-align: right;
  }
  .parentdiv {
    width: 500px;
  }
  .heroListButton {
    font-size: 24px;
    font-weight: 700;
    padding-inline: 30px;
  }
  .joinButton {
    font-size: 24px;
    font-weight: 700;
    padding-inline: 30px;
  }
  .whatsapp {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
}
