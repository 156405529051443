/* src/components/NotFound.css */
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  font-family: "Arial", sans-serif;
}

.not-found-content {
  text-align: center;
  color: #333;
}

.not-found-title {
  font-size: 8rem;
  color: #0c60ca;
}

.not-found-text {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #666;
}

.not-found-link {
  margin-top: 2rem;
  display: inline-block;
  padding: 10px 20px;
  background-color: #0c60ca;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found-link:hover {
  background-color: #ff3b3b;
}
