#testimonials {
  background: url("/src/assets/images/Testimonial_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 72vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-family: "Kumbh Sans", sans-serif;
  max-width: 1500px;
}

@media only screen and (device-width: 412px) and (device-height: 915px) {
  #testimonials {
    background: url("/src/assets/images/Testimonial_bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    font-family: "Kumbh Sans", sans-serif;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  #testimonials {
    background: url("/src/assets/images/Testimonial_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 74vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    font-family: "Kumbh Sans", sans-serif;
  }
}

.resized-image {
  width: 100%;
  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.testimonial-heading {
  padding-top: 40px;
  font-size: 24px;
  color: #0c60ca;
  font-weight: 600;
  text-align: center;
}

#testimonials .cst-slide-img > div > img:first-child {
  z-index: -1;
  top: 7%;
}

#testimonials .cst-slide-img > div > img + img {
  position: absolute;
  left: 10%;
  top: 0;
  width: 75%;
  height: 75%;
}

#testimonials .cst-slide-info {
  width: 60%;
}

#testimonials .cst-slide-info > p {
  font-size: 12px;
  font-weight: 500;
  position: relative;
  /* padding: 1rem 0 0 1rem; */
}

#testimonials .carousel-indicators {
  bottom: -20%;
}

#testimonials .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;

  border-radius: 100%;
  background-color: #0c60ca92;
}

.carousel-indicators .active {
  background-color: #1666cc !important;
}

#testimonials .carousel-indicators {
  justify-content: flex-start;
  bottom: -70%;
  left: -13%;
}

.comma2 {
  width: 15%;
  position: absolute;
  bottom: -10px;
}

.simple-icons_comma {
  width: 15%;
  position: absolute;
  top: -10px;
  left: -10px;
}

.highlight {
  color: #df9b28;
  font-weight: bold;
  padding: 2px;
}

.img-txt {
  align-items: center;
}

.neighbours {
  width: full;
  margin-top: 80px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
}

.neighbours p {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.neighbours img {
  width: 140px;
}
@media (min-width: 1024px) {
  #testimonials {
    background-image: none;
    width: 90vw;
    margin-block: 100px;
    background-color: #ffd33f;
    padding: 20px;
    border-radius: 5px;
    height: 100%;
  }
  .testimonial-wrapper {
    background-color: #d4e7ff;
    border-radius: 5px;
    padding: 20px;
  }
  .cst-slide-img {
    flex: 1;
  }
  .cst-slide-img {
    flex: 1;
  }
  .testimonial-heading {
    padding-top: 0;
    font-weight: 600;
    font-size: 64px;
    text-align: center;
  }
  .img-txt img {
    object-fit: contain;
    width: 100%;
    height: 200px;
  }
  .neighbours {
    width: full;
    margin-top: 150px;
  }
  #testimonials .carousel-indicators {
    justify-content: flex-start;
    bottom: -150%;
    left: 30%;
  }
}
@media (min-width: 380px) and (max-width: 700px) {
  #testimonials {
    background-size: cover;
  }
}

@media (min-width: 700px) and (max-width: 1023px) {
  #testimonials {
    background-image: none;
    width: 90vw;
    margin-block: 100px;
    background-color: #ffd33f;
    padding: 10px;
    border-radius: 5px;
    height: 100%;
  }
  .testimonial-wrapper {
    background-color: #d4e7ff;
    border-radius: 5px;
    padding: 20px;
  }
  .cst-slide-img {
    flex: 1;
  }
  .cst-slide-img {
    flex: 1;
  }

  .img-txt img {
    object-fit: contain;
    width: 100%;
    height: 200px;
  }
  .neighbours {
    width: full;
    margin-top: 150px;
  }
  #testimonials .carousel-indicators {
    justify-content: flex-start;
    bottom: -150%;
    left: 30%;
  }
}
