.containera {
  width: 100vw;

  padding: 20px;
  position: relative;
}

.vendor-button {
  padding: 10px 10px;
  border-radius: 4px;
  background-color: #93c5fd; /* blue-300 */
  cursor: pointer;
  border: none;
  margin-right: 10px;
}

.card-container {
  margin-block: 20px;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.add-cluster-button {
  margin-top: 8px;
  position: absolute;
  top: -56px;
  right: 8px;

  z-index: 20;
  cursor: pointer;
}

.cluster-wrapper {
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
}
