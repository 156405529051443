.help {
  width: 100vw;
  max-width: 1500px;
}
.how-iLaqa-helps {
  margin-top: -100px;
  display: block;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/Group\ 114.png");
  height: 90vh;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 82vw 80vh;
  padding-left: 20px !important;
  position: relative;
}
@media only screen and (device-width: 412px) and (device-height: 915px) {
  .how-iLaqa-helps {
    margin-top: -100px;
    display: block;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Group\ 114.png");
    height: 90vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 73vw 75vh;
    padding-left: 20px !important;
    position: relative;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) {
  .how-iLaqa-helps {
    margin-top: -100px;
    display: block;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Group\ 114.png");
    height: 90vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 75vw 75vh;
    padding-left: 20px !important;
    position: relative;
  }
}

@media only screen and (device-width: 360px) and (device-height: 740px) {
  .how-iLaqa-helps {
    margin-top: -100px;
    display: block;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Group\ 114.png");
    height: 90vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 82vw 80vh;
    padding-left: 20px !important;
    position: relative;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .how-iLaqa-helps {
    margin-top: -100px;
    display: block;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Group\ 114.png");
    height: 100vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 80vw 90vh;
    padding-left: 20px !important;
    position: relative;
  }
}
@media only screen and (device-width: 390px) and (device-height: 844px) {
  .how-iLaqa-helps {
    margin-top: -100px;
    display: block;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Group\ 114.png");
    height: 90vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 78vw 80vh;
    padding-left: 20px !important;
    position: relative;
  }
}

@media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
  .how-iLaqa-helps {
    margin-top: -100px;
    display: block;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Group\ 114.png");
    height: 90vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 73vw 76vh;
    padding-left: 20px !important;
    position: relative;
  }
}

.left-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #0c60ca;
  width: 210px;
}

.left-section h2 span {
  font-size: 24px;
  color: #df9b28;
}

.left-section {
  position: absolute;
  top: 15%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
}

.left-section ul {
  padding-inline: 20px;
  width: 310px;
  margin-bottom: 20px;
}

.left-section ul li {
  font-size: 12px;
  font-weight: 500;
  padding-right: 15px;
}

.left-section p {
  font-size: 12px;
  margin-bottom: 20px;
}

.buttons {
  display: block;
  flex-direction: row;
  gap: 10px;
}

.contact-button,
.blacklist-button {
  padding: 10px 15px;
  text-align: center;
  z-index: 1000;
  position: relative;
  font-size: 12px;
  border: none;
  font-weight: 700;
  border-radius: 20px;
  cursor: pointer;
  z-index: 1;
}

.contact-button {
  background-color: #0c60ca;
  color: #fff;
}

.blacklist-button {
  margin-top: 20px;
  background-color: #ffe484;
  color: #0c60ca;
}

.right-section {
  position: absolute;
  bottom: 0;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
  width: 240px;
}

.right-section ul {
  list-style-type: none;
}

.right-section li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.right-section li img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.right-section li span {
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  line-height: 1.2;
}

.group-2 {
  margin-top: -100px;
  width: 300px;
  height: 300px;
  object-fit: fill;
}

.group2-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.otherservices-in-help {
  position: absolute;

  left: 0;
  right: 0;
  bottom: 0;
}
.whatsappinhelp {
  width: 20px;
  height: 20px;
}
@media (min-width: 1024px) {
  .otherservices-in-help {
    display: none;
  }
  .how-iLaqa-helps {
    background-size: 40vw 130vh;
    height: 140vh;
    margin-top: -450px;
  }
  .left-section h2 {
    font-size: 64px;
    width: 610px;
  }
  .left-section {
    padding-left: 60px;
  }
  .left-section h2 span {
    font-size: 64px;
  }
  .left-section ul li {
    font-size: 24px;
    font-weight: 500;
  }
  .left-section ul {
    padding-inline: 20px;
    width: 610px;
    margin-bottom: 20px;
  }
  .contact-button {
    font-size: 24px;
    font-weight: 700;
  }

  .blacklist-button {
    font-size: 24px;
    font-weight: 700;
  }
  .whatsappinhelp {
    width: 36px;
    height: 36px;
    margin-left: 5px;
  }
  .group-2 {
    width: 670px;
    height: 670px;
    object-fit: fill;
  }
  .group2-wrapper {
    justify-content: flex-end;
    padding-right: 100px;
  }
}
@media (min-width: 700px) and (max-width: 1023px) {
  .how-iLaqa-helps {
    background-size: 60vw 100vh;
    height: 100vh;
    margin-top: -200px;
  }
  .left-section h2 {
    font-size: 50px;
    width: 100%;
  }

  .left-section h2 span {
    font-size: 50px;
  }
  .left-section ul li {
    font-size: 18px;
    font-weight: 500;
  }
  .left-section ul {
    padding-inline: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-button {
    font-size: 18px;
    font-weight: 700;
  }

  .blacklist-button {
    font-size: 18px;
    font-weight: 700;
  }
  .whatsappinhelp {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }
  .group-2 {
    width: 670px;
    height: 670px;
    object-fit: fill;
  }
  .group2-wrapper {
    justify-content: flex-end;
    padding-right: 100px;
  }
}
