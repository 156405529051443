.data-container {
  max-width: 24rem; /* Equivalent to Tailwind's max-w-sm */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.1); /* Equivalent to Tailwind's shadow-lg */
  background-color: rgba(64, 124, 255, 0.3); /* Equivalent to bg-[#407cff4d] */
  margin-top: 4px; /* Equivalent to mt-1 */
  display: flex;
  flex-wrap: wrap;
}

.data-item {
  padding: 4px 16px; /* Equivalent to px-4 py-1 */
  display: flex;
  gap: 16px; /* Equivalent to gap-4 */
  justify-content: space-between;
  align-items: center;
}

.data-name {
  font-weight: 400; /* Equivalent to font-normal */
  font-size: 1rem; /* Equivalent to text-md */
  margin-bottom: 8px;
}

.data-count {
  color: #4a5568; /* Equivalent to text-gray-700 */
  font-size: 1.125rem; /* Equivalent to text-lg */
}
