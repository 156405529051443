.container-s {
    width: 100vw;
    padding: 20px;
    position: relative;
  }
  
  .shop-button {
    padding: 10px 10px;
    border-radius: 4px;
    background-color: #f87171; /* red-400 */
    cursor: pointer;
    border: none;
  }
  
  .card-container {
    margin-block: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .add-shop-cluster-button {
    margin-top: 8px;
    position: absolute;
    top: -56px;
    right: 8px;
    z-index: 20;
    cursor: pointer;
  }
  
  .cluster-wrapper {
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
  