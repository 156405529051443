.formContainer-emergency-admin-add {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formContainer-emergency-admin-add form {
  display: flex;
  flex-direction: column;
}
.formContainer-emergency-admin-add .previewImage {
  margin-top: 10px;
  max-width: 100%;
  max-height: 200px;
  border-radius: 5px;
}

.formContainer-emergency-admin-add label {
  margin-bottom: 15px;
  font-weight: bold;
}

.formContainer-emergency-admin-add input[type="text"],
.formContainer-emergency-admin-add textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
}

.formContainer-emergency-admin-add textarea {
  resize: vertical;
}

.formContainer-emergency-admin-add button {
  padding: 10px 20px;
  background-color: #4c9aff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.formContainer-emergency-admin-add button:hover {
  background-color: #3b82e3;
}
