.modal-overlay-shopModal {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-phone-link {
  text-decoration: none;
}

.shop-modal-white-space-container {
  margin-bottom: 15px;
  display: flex;
  margin-top: -20px;
  flex-direction: row;
}

.phone-box {
  margin-top: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #0c60ca;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  font-weight: 600;
  font-family: "Kumbh Sans";
  border-radius: 25px;
  color: black;
  font-size: 16px;
}

.shop-phone {
  margin: 0;
}

.round-image-container {
  width: 75px;
  height: 75px;
  background-color: #eaf2fc;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.modal-content-shopModal {
  background-color: white;
  border-radius: 15px;
  width: 70%;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.modal-header {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.shop-modal-close-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.modal-body {
  text-align: center;
}

.shop-image-modal {
  width: 45px;
  height: 45px;
}

.modal-shop-name-lessthan {
  font-size: 20px;
  margin-top: 25px;
  display: inline-block;
  font-family: "Kumbh Sans";
  color: black;
  font-weight: bold;
  margin-bottom: 5px;
}

.modal-shop-name {
  font-size: 20px;
  margin-top: 20px;
  word-break: break-all;
  white-space: normal;
  max-width: 20ch;
  display: inline-block;
  font-family: "Kumbh Sans";
  color: black;
  font-weight: bold;
  margin-bottom: 5px;
}

.shop-distance {
  font-weight: 500;
  color: black;
  font-family: "Kumbh Sans";
  margin-bottom: 5px;
}

.shop-phone a {
  text-decoration: none;
  color: inherit;
}

.reason-container {
  text-align: left;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-left: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #eaf2fc;
}

.call-icon-modal-container {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #0a820f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-icon-modal {
  width: 16px;
  height: 16px;
}

.shop-phone-container-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-line {
  display: inline;
  align-items: center;
  justify-content: flex-start;
}

.status-icon-modal {
  margin-bottom: 50px;
  margin-left: -10px;
}

.timing-line {
  margin-top: 5px;
}

.details-container {
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
}

.icon-container-logo {
  display: flex;
  gap: 20px;
}

.icon-logo {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .modal-shop-name {
    font-size: 15px;
    font-weight: 600;
  }

  .shop-distance {
    font-size: 12px;
    font-weight: 600;
  }

  .shop-phone {
    font-size: 12px;
  }

  .reason-container {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
  }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 1024px) {
  .modal-shop-name {
    font-size: 24px;
  }

  .shop-distance {
    font-size: 18px;
  }

  .shop-phone {
    font-size: 18px;
  }

  .reason-container {
    font-size: 18px;
  }
}

@media (min-width: 1025px) {
  .modal-content-shopModal {
    max-width: 300px;
  }

  .modal-shop-name {
    font-size: 18px;
  }

  .shop-distance {
    font-size: 14px;
  }

  .shop-phone {
    font-size: 12px;
  }

  .reason-container {
    font-size: 14px;
  }
}
