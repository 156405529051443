.search-container-admin {
  padding: 8px 16px; /* Equivalent to px-4 py-2 */
  width: 100%; /* Equivalent to w-full */
  border: 1px solid #4b5563; /* Equivalent to border-gray-600 */
  border-radius: 40px; /* Equivalent to rounded-xl */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input-admin {
  background-color: white;
  margin: 0;
  outline: none;
  flex: 1; /* Equivalent to flex-1 */
  padding: 0;
}
