.add-cluster-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  color: white;
  border-radius: 4px;
  background-color: #407cff;
  border: none;
  cursor: pointer;
}

.add-cluster-button:hover {
  background-color: #1d4ed8; /* Equivalent to Tailwind's bg-blue-700 */
}
