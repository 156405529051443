/* Container for the whole form */
.profileform {
  max-width: 1024px;
  padding-bottom: 40px;
  width: 100%;
}
.profileform .form-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

/* Form group and inputs */
.profileform .form-group {
  margin-bottom: 20px;
}

.profileform label {
  display: block;
  font-size: 14px;
  color: #007bff;
  margin-bottom: 8px;
}

.profileform input,
.profileform textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px; /* Slight rounding for a cleaner look */
  background-color: #f0f8ff;
  border: 1px solid #ccc;
}

.profileform textarea {
  height: 80px;
  resize: none;
}

.profileform input:focus,
.profileform textarea:focus {
  outline: none;
  border-color: #007bff;
}

.avatar-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-box {
  width: 130px;
  height: 130px;
  background-color: lightgray;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.avatar-box p {
  font-size: 16px;
  color: #333;
}
.avtar-box-image {
  width: 70px;
  height: 70px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.avtar-image {
  background-color: #fff;
  width: 140px;
  height: 140px;
  object-fit: contain;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

/* Popup styles */
.profileform .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.profileform .popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.profileform .popup-content button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

/* Save button */
.save-button {
  background-color: #0c60ca;
  color: white;

  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
}

.save-button:hover {
  background-color: #0056b3;
}

/* Bottom modal styles */
.profileform .bottom-modal-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align modal at the bottom */
}

.profileform .bottom-modal {
  background-color: white;
  width: 100%;
  max-width: 500px;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  text-align: center;
  animation: slide-up 0.4s ease-in-out;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Modal content */
.profileform .modal-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.profileform .modal-option {
  cursor: pointer;
  text-align: center;
}

.profileform .modal-option img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.profileform .remove-text {
  justify-content: end;
  font-size: 16px;
  display: flex;
  color: #ff4d4d;
  cursor: pointer;
  margin-top: 10px;
}

.profileform .remove-text:hover {
  text-decoration: underline;
}
.asterisk {
  color: red;
  font-size: 15px;
}
.otp-boxes input {
  border-radius: 10px;
  color: #0056b3;
}
