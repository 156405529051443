@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.emergency-card-container {
  min-height: 100vh;
  overflow-y: auto;
}

.emergency-type-group {
  margin-bottom: 30px;
}

.emergency-type-heading {
  font-size: 14px;
  color: #0c60ca;
  font-weight: bold;
  margin: 5px 10px;
  padding-left: 10px;
}

.emergency-row {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  overflow-x: auto;

  margin-bottom: 20px;
}

.card {
  background-color: #e1f0ff;
  padding-top: 10px;
  border-radius: 10px;
  max-width: 40%;
  text-align: center;
  width: 100%;
  opacity: 0;
  animation: fadeInTop 0.5s forwards;
  margin: 0 10px;
  flex: 0 0 auto;
  cursor: pointer;
}

.card.delay-0 {
  animation-delay: 0s;
}

.card.delay-1 {
  animation-delay: 0.2s;
}

.card.delay-2 {
  animation-delay: 0.4s;
}
.emergency-image-container {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Centers the container */
}

.emergency-image-card {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: auto;
}

.emergency-name {
  font-size: 14px;
  font-family: "Kumbh Sans";
  font-weight: 700;
  color: black;
  margin: 5px 0 0px;
}

.emergency-role {
  font-size: 12px;
  font-weight: 500;
  color: black;
  font-weight: bold;
  margin-bottom: 2px;
}

.emergency-contact {
  font-size: 12px;
  margin-bottom: 0px;
  color: black;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.call-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: -18px;
}

.emergency-reason {
  font-size: 12px;
  background-color: #d9d9d9;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: left;
  color: black;
  padding: 5px;
  width: 100%;
  left: 0px;
  margin: 10px 0px 0 0px;
  word-wrap: break-word; /* Ensures long words break into a new line */
  overflow-wrap: break-word; /* Adds support for all browsers */
  white-space: normal; /* Ensures text wraps when reaching the card width */
  width: 100%;
}

.emergency-reason strong {
  font-weight: bold;
  color: #4c9aff;
}

.emergency-reason a {
  color: #4c9aff;
  text-decoration: none;
  font-weight: bold;
}

.emergency-reason a:hover {
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .emergency-name {
    font-size: 32px;
  }
  .emergency-reason {
    font-size: 20px;
    height: 200px;
  }
  /* .emergency-card-upper {
    height: 300px;
  } */
  .emergency-type-heading {
    font-size: 28px;
  }

  .emergency-image-container,
  .emergency-image-card {
    width: 161px;
    height: 161px;
  }

  .emergency-role {
    font-size: 24px;
  }

  .emergency-contact {
    font-size: 20px;
  }

  .call-icon {
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .emergency-name {
    font-size: 24px;
  }
  .emergency-reason {
    font-size: 16px;
  }
  /* .emergency-card-upper {
  height: 300px;
} */
  .emergency-type-heading {
    font-size: 28px;
  }

  .emergency-image-container,
  .emergency-image-card {
    width: 120px;
    height: 120px;
  }

  .emergency-role {
    font-size: 28px;
  }

  .emergency-contact {
    font-size: 26px;
  }

  .call-icon {
    height: 30px;
    width: 30px;
  }
}
