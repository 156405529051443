.auth-container-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-container {
  width: 100%;
  padding-inline: 20px;
}
.mobile-number-section {
  padding-block: 30px;
  display: flex;
  flex-direction: column;
}
.mobile-number-section-input {
  width: 100%;
  padding-inline: 12px;
  padding-block: 8px;
  background-color: #eaf2fc;
  border: none;

  border-radius: 3px;

  font-size: 16px;
  margin-top: 10px;
}
.mobile-number-section-input ::placeholder {
  color: gray;
}
.mobile-number-section-button {
  margin-top: 40px;
  font-weight: 600;
  font-size: 14px;
  background-color: #0c60ca;
  border-radius: 20px;
  padding: 15px;
  padding-block: 8px;
  color: white;
  border: none;
}
.mobile-number-section-heading {
  font-weight: 700;
  font-size: 16px;
  color: #0c60ca;
  text-align: left;
}
.mobile-number-section-button-wrapper {
  display: flex;
  justify-content: center;
}
.enabled-button {
  opacity: 1;
}
.disabled-button {
  opacity: 0.5;
}
.otp-section {
  margin-block: 20px;
}
.enter-otp {
  color: #0c60ca;
  font-weight: 700;
  font-size: 16px;
}
.otp-boxes {
  display: flex;
  gap: 20px;
}
.otp-box {
  width: 36px;
  height: 36px;
}
.verify-btn {
  margin-top: 30px;
  background-color: #0c60ca;
  color: white;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 700;
  padding-inline: 50px;
  padding-block: 8px;
  border: none;
}
.disable-div {
  pointer-events: none;
  opacity: 0.5;
}
.errormessage {
  color: #cc0000;
  font-size: 12px;
  text-align: left;
  padding: 0;
  margin: 0;
}
input:focus {
  border: 1px solid #0c60ca;
}
@media (min-width: 1024px) {
  .auth-container {
    width: 50vw;

    border-radius: 40px;
  }
}
