.emergency-container {
  width: 100%;
  margin-top: 8px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.1);
  padding: 4px 16px;
  background-color: white;
}

.emergency-content {
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.emergency-image {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 16px;
}

.emergency-info {
  flex: 1;
}

.emergency-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.emergency-area,
.emergency-type {
  color: #4a5568;
  font-size: 1rem;
  margin-bottom: 4px;
}

.emergency-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  gap: 16px;
  margin-top: 16px;
}

.delete-button,
.edit-button {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  background-color: #3b82f6;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.delete-button:hover,
.edit-button:hover {
  background-color: #2563eb;
}