.modal-overlay-admin-drive-modal {
  z-index: 200;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  padding-bottom: 20px;
}
.modal-ontent-admin-drive-modal {
  background-color: white !important;
  border-radius: 20px !important;
  z-index: 50;
  border-radius: 10px;
  padding: 20px;
  padding-block: 50px;

  position: relative;
}
