.form-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label {
  display: block;
}

.input {
  border: 1px solid #ccc;
  padding: 8px;
  width: 100%;
}

.building-container {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
}

.delete-button {
  padding: 8px 16px;
  background-color: #f56565; /* red-500 */
  color: white;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-container {
  display: flex;
  gap: 8px;
}

.add-button,
.submit-button,
.cancel-button-cluster {
  padding: 8px 16px;
  color: white;
  border: none;
  border-radius: 4px;
}

.add-button {
  background-color: #48bb78; /* green-500 */
}

.submit-button {
  background-color: #4299e1; /* blue-500 */
}

.cancel-button-cluster {
  background-color: #4299e1; /* blue-500 */
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
