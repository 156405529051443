.modal-overlay-serviceModal {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;

}

.modal-content-serviceModal {
  padding: 20px;
  background-color: white;
  padding: 10px;
  width: 45%;
  max-height: min-content;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.close-icon {
  cursor: pointer;
  border: none;
  background: none;
  font-size: 18px;
  color: #007BFF;
}

.modal-body {
  text-align: center;
}

.modal-item {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
}

hr {
  border: none;
  border-top: 1.5px solid #3b3f40;
  margin: 10px 0;
}