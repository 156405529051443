.vendor-blacklist-page {
  max-width: 1200px;
  padding-bottom: 40px;
}
.vendor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-weight: 700;
  color: #0c60ca;
  font-size: 20px;
  margin: 0;
}

.no-result-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 18px;
  color: #888;
  text-align: center;
}

.header-icon {
  width: 24px;
  height: 24px;
}
.shop-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns layout */
  gap: 20px;
  justify-items: stretch;
  padding: 0;

  padding: 10px;
}
@media (min-width: 1024px) {
  .vendor-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .header-icon {
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
  .header-title {
    font-size: 48px;
  }

  .shop-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 columns layout */
    gap: 20px;
    justify-items: stretch;
    padding: 0;
    margin-top: 40px;
  }
}
