.header-hero-wrapper {
  background-image: url("./assets/images/Ellipse1.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 120px 380px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: "Kumbh Sans", sans-serif;
  overflow-x: hidden;
  overflow-y: auto !important;
  width: 100vw;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.popup-overlay.show {
  opacity: 1;
  visibility: visible;
}

.popup {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 8px;
}

.popup p {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 600px;
  font-weight: 500;
  margin: 20px 0;
  word-wrap: break-word;
}

.popup img {
  max-width: 100px;
  margin-bottom: 20px;
}

.popup button {
  padding: 10px 30px;
  background-color: #007bff;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
button {
  padding: 10px 20px;
  border: none;
  outline: none;
  z-index: 1;
}
@media (min-width: 1024px) {
  .header-hero-wrapper {
    background-image: none;
  }
}
