#TopVendorBlacklist {
  padding: 20px;
}

#TopVendorBlacklist > img {
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeIn 4s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
@media (min-width: 1024px) {
  #TopVendorBlacklist > img {
    height: 500px;
  }
  #TopVendorBlacklist {
    padding: 20px;
    padding-inline: 80px;
  }
}
