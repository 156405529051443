.insufficient-balance-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.insufficient-balance-modal-container {
  background-color: white;
  color: black;
  padding: 20px;
  width: 300px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  text-align: center; 
  z-index: 1000;
  font-family: Arial, sans-serif;
  font-size: 14px;
  border: solid #1976D2;
  border-radius: 10px; /* Keeps the rounded corners */
}

/* Remove the downward arrow completely */
.insufficient-balance-modal-container::after {
  content: none;
}

/* Updated cross button with larger size and moved upwards */
.insufficient-close-btn {
  position: absolute;
  top: -5px; /* Move it slightly higher */
  right: 5px; /* Keep it near the right edge */
  background: none;
  border: none;
  font-size: 28px; /* Increase the font size */
  color: #1976D2;
  cursor: pointer;
  font-weight: bold;
  border-radius: 50%;
  padding: 5px;
  transition: background-color 0.3s ease;
}

/* On hover, background color changes */
.insufficient-close-btn:hover {
  background-color: #f0f0f0;
  color: #ff4d4d;
}

.insufficient-balance-message {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.emoji {
  margin-top: 10px;
}

.emoji img {
  width: 40px;
  height: 40px;
}
