.our-ceo {
  padding-inline: 18px;
}
.ceo-image {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 40px;
}
.letter-ceo {
  margin-block: 25px;
}
