.drive-heading {
  font-size: 24px;
  color: #0051b8;
  font-weight: 600;
}
.drive-heading span {
  color: #df9b28;
  font-weight: 600;
}
.drive-container {
  margin-top: 30px;
  margin-bottom: 40px;
}
.drive-area-form {
  margin-block: 20px;
  display: flex;
  gap: 20px;
  font-weight: 600;
  font-size: 12px;
  color: black;
}
.drive-area-form label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.tab {
  font-weight: 700;
  font-size: 10px;
}
.drives-wrapper {
  margin-block: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 20vh;
  max-height: 35vh;
  overflow-y: scroll;
}
.drive-card {
  border-radius: 10px;
  border: 1px solid #cbe2fe;
  box-shadow: #eaf2fc;
  display: flex;
  align-items: flex-start;

  gap: 5px;

  padding: 5px;
}

.drive-card-date {
  border-radius: 4px;
  padding-block: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0c60ca;
  gap: 2px;
  flex: 1;
  height: 100% !important;
}
.drive-card-date img {
  height: 20px;
  width: 20px;
}
.drive-card .date-month {
  font-size: 10px;
  font-weight: 700;
  color: white;
}
.drive-card .week {
  font-size: 10px;
  font-weight: 400;
  color: white;
}
.drive-card-text {
  flex: 3;
}
.drive-card-heading {
  color: #0c60ca;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Compatibility with older browsers */
  overflow-wrap: break-word; /* Modern browsers */
}

.drive-card-desci {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0;
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Compatibility with older browsers */
  overflow-wrap: break-word; /* Modern browsers */
}

.drive-card-desci span {
  font-weight: 700;
}
.drive-card-button {
  flex: 1;
  /* display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drive-book-now {
  font-size: 8px;
  font-weight: 700;
  background-color: #0c60ca;
  border-radius: 20px;
  color: white;

  /* padding-inline: 10px; */

  padding: 0;
  padding-block: 2px;
  padding-inline: 6px;
}
.drive-read-more {
  font-weight: 400;
  font-size: 8px;
  color: #0c60ca;
}
.no-events-message {
  text-align: center;
  margin-top: 20px;
  color: #555;
  font-size: 10px;
  font-weight: 500;
  font-style: italic;
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.drives-wrapper::-webkit-scrollbar {
  display: block;
}
.drives-wrapper {
  overflow: auto; /* Ensure scrolling is enabled */
}

.drives-wrapper::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  height: 8px; /* Set the height of the scrollbar (for horizontal scrollbars) */
}

.drives-wrapper::-webkit-scrollbar-thumb {
  background: #888; /* Style the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.drives-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; /* Style the scrollbar track */
}
@media (min-width: 1024px) {
  .drive-heading {
    font-size: 48px;
  }
  .drive-area-form {
    font-size: 20px;
  }
  .tab {
    font-size: 20px;
  }
  .drive-card-heading {
    font-size: 20px;
  }
  .drive-card-desci {
    font-size: 15px;
  }
  .drive-book-now {
    font-size: 15px;
  }
  .drive-card .date-month {
    font-size: 15px;
  }
  .drive-card .week {
    font-size: 15px;
  }
}
