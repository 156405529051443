.inprogressvouchermodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.inprogressvouchermodal-content {
  background-color: white;
  color: black;
  padding: 20px;
  width: 300px;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.inprogressvouchermodal-body {
  text-align: center;
}

.inprogressvouchermodal-content::after {
  position: absolute;
  bottom: -10px; /* Adjust this value to align with the ghost emoji */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f1f1f1;
}
.inprogressvouchermodal-header {
  position: absolute;
  top: 10px;
  right: 10px;
}
