.redeem-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* High z-index */
}

.redeem-modal-container {
  background-color: white;
  border-radius: 20px;
  width: 300px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.redeem-modal-content {
  text-align: center;
}
.redeem-para {
  font-size: 11px;

  margin-top: 1rem;
  display: flow;
}
.emoji {
  font-size: 30px;
  margin-top: 20px;
}

.redeem-close-button {
  margin-top: 15px;
  background-color: #008cba;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
