.vouchermodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Modal content (Speech bubble) */
.vouchermodal-content {
  background-color: white;
  color: black;
  padding: 20px;
  width: 300px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Speech bubble pointer */
.vouchermodal-content::after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.vouchermodal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.email-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.email-link:hover {
  text-decoration: underline;
}

.shopping-bag-icon {
  width: 50px;
  margin-top: 10px;
}
