.form-container-admin-createshop {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container-admin-createshop form {
  display: flex;
  flex-direction: column;
}

.form-container-admin-createshop label {
  margin-bottom: 15px;
  font-weight: bold;
}

.form-container-admin-createshop input[type="text"],
.form-container-admin-createshop textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
  /* border: 1px solid #ccc; */
}
.form-container-admin-createshop .timePicker input {
  width: 100px; /* Adjust the width as per your needs */
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}

.form-container-admin-createshop .timePicker input:focus {
  border-color: #007bff; /* Blue border when focused */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-container-admin-createshop textarea {
  resize: vertical;
}

.form-container-admin-createshop
  .form-container-admin-createshop.closeOnSelector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.form-container-admin-createshop button {
  padding: 10px 20px;
  background-color: #4c9aff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container-admin-createshop button:hover {
  background-color: #3b82e3;
}
