:root {
  --primary-color: #0c60ca;
  --secondary-color: #df9b28;
  --highlight-color: #407bff;
  --gradient-start: #f0b70c;
  --gradient-middle: #e9b33c;
  --gradient-end: #dd3822;
  --border-color: #cde3ff;
  --font-family: "Kumbh Sans";
  --text-color: gray;
  --background-color: #fff;
}

body {
  margin: 0;
  width: 100vw;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:focus {
  border-color: #0c60ca; /* Blue border when focused */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
h2 {
  margin-block: 12px;
}
