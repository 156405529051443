.Los-Services-Topbar {
  display: flex;
  gap: 5px;
}
.Los-Services-Topbar-left {
  display: flex;
  gap: 5px;
  align-items: center;
}
.servicelist-backbtn {
  background-color: white;
  border: none;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.servicelist-backbtn img {
  width: 18px;
  height: 18px;
}

.servicelist-title {
  margin-left: 5px;
  word-wrap: break-word;
  width: 100px;
  color: #0c60ca;
  font-size: 20px;
  font-weight: 700;
}

.servicelist-los-search-button img {
  fill: #007bff;
  width: 17px;
  height: 17px;
}
@media (min-width: 1024px) {
  .Los-Services-Topbar > * {
    flex: 1;
  }
  .Los-Services-Topbar-left {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .servicelist-title {
    width: auto;

    font-size: 48px;
  }
  .servicelist-backbtn img {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
}
