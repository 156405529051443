.voucher-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100vw;
}
.voucher-form {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

.form-group {
  margin-bottom: 16px;
}

.voucher-form-wrapper .form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: #333;
}

.voucher-form-wrapper .form-group input[type="file"] {
  padding: 4px;
  font-size: 0.9rem;
}

.voucher-form-wrapper .form-group input[type="text"],
.voucher-form-wrapper .form-group input[type="date"] {
  width: 100%;
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.submit-button:hover {
  background-color: #0056b3;
}
