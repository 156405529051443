#heroService {
  width: 100%;
}
.cstSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem;
}

#cstSlider .carousel-indicators {
  justify-content: flex-start;
  bottom: -60%;
  left: -15%;

  background-color: #333; /* Custom dot color */
  border-radius: 50%; /* Make dots circular */
}

.carousel-indicators .active {
  background-color: #000; /* Active dot color */
}
#heroService .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;

  border-radius: 100%;
  background-color: #0c60ca92;
}
.cstImg {
  /* max-width: 100%;
  height: auto; */
  padding: 0 2rem 1rem;
}
@media (min-width: 1024px) {
  #cstSlider .carousel-indicators {
    bottom: -30%;
    left: -15%;
  }
  #heroService {
    width: 1024px;
    height: 100vh;
  }
}
