.image-maximise-overlay {
  position: fixed;
  z-index: 9999;
  top: 19%;
  left: 10%;
  width: 80%;
  height: 63%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-maximise-content {
  background-color: white;
  border-radius: 10px;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image-maximise-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
}

.image-maximise-close-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.image-maximise-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.image-maximise-img {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .image-maximise-content {
    width: 90%;
    height: 90%;
  }
}