/* AddService Component Styles */
.add-service-container {
  /* max-width: 600px; */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-service-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.add-service-container label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

.add-service-container input[type="text"],
.add-service-container textarea,
.add-service-container select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.add-service-container textarea {
  resize: vertical;
  height: 80px;
}

.add-service-container button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-service-container button:hover {
  background-color: #0056b3;
}

.sub-service-container {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sub-service-container p {
  margin: 0;
  font-size: 12px;
  font-weight: 500px;
}
.sub-service-container h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #444;
}

.sub-service-container h4 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 700;
}

.sub-service-detail {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.sub-service-detail input {
  flex: 1;
}

.sub-service-container button {
  background-color: #28a745;
}

.sub-service-container button:hover {
  background-color: #218838;
}
