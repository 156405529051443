.services {
  padding-inline-start: 10px;
  text-align: center;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none; /* Hides only the horizontal scrollbar in WebKit browsers */
}

.background-image {
  position: absolute;
  align-self: flex-start;
  right: 0;
  width: 40%;
  height: 50%;
  object-fit: cover;
}

.service-cards-container {
  overflow-x: hidden;
  white-space: nowrap;
}

.service-cards {
  width: 90vw;
  overflow: scroll;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;

  margin-left: 10px;
}

.service-card {
  transition: transform 0.3s ease;
  display: inline-block;
  margin-block: 10px;
  margin-inline: 5px;
}
.service-card img {
  width: 100px;
  height: 82px;
  box-shadow: 1px 1px 1px 1px rgba(153, 204, 234, 0.02);
}

.service-card h3 {
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  width: 90px;
  word-wrap: break-word;
  margin-top: 10px;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  color: inherit;
}

.offset-up {
  transform: translateY(-10px);
}

.offset-down {
  transform: translateY(10px);
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }
  50% {
    opacity: 1;
    transform: translateY(0) scale(1.05);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@media (min-width: 500px) and (max-width: 650px) {
  .services {
    overflow: scroll;
  }
  .service-cards {
    justify-content: center;
    gap: 20px;
    padding-left: 100px;
  }
  .services {
    padding-block: 60px;
  }
  .service-card img {
    width: 150px;
    height: 120px;
  }
  .service-card h3 {
    width: 150px;
    font-size: 18px;
    font-weight: 600;
  }
  .service-card {
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 650px) and (max-width: 1100px) {
  .arrows {
    display: none;
  }
  .service-cards {
    justify-content: center;
    gap: 20px;
  }
  .services {
    padding-block: 60px;
  }
  .service-card img {
    width: 150px;
    height: 120px;
  }
  .service-card h3 {
    width: 150px;
    font-size: 18px;
    font-weight: 600;
  }
  .service-card {
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 1101px) {
  .service-card img {
    width: 204px;
    height: 190px;
  }
  .arrows {
    display: none;
  }
  .service-cards {
    justify-content: center;
    gap: 50px;
  }
  .service-card h3 {
    width: 204px;
    font-size: 24px;
    font-weight: 600;
  }
}
