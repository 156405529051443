.our-journey-page {
  padding-inline: 18px;
}
.who-we-are-our-journey {
  display: flex;
  flex-direction: column;
}
.journey-meaning-mission {
  margin-top: 25px;
}
.journey-meaning-mission-para {
  background-color: #deedff;
  border-radius: 10px;
  padding-inline: 8px;
  padding-block: 10px;
}
.key-supporters {
  margin-block: 30px;
}
.key-supporters-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.key-supporters-section-img {
  width: 130px;
  height: 130px;
  border-radius: 10px;
}
.key-supporters-heading {
  font-size: 24px;
  text-align: center;
  align-items: center;
  font-weight: 700;

  color: #0c60ca;
  line-height: 36px;
}
.key-supporters-names {
  font-size: 12px;
  align-items: center;
  font-weight: 700;

  color: #0c60ca;
}
.key-supporters-section-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1024px) {
  .our-journey-page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* Two equal columns */
    grid-template-rows: auto 1fr auto; /* First row auto, second row flex, third row auto */
    justify-content: space-between;
    gap: 20px;
  }

  .who-we-are-our-journey {
    grid-column: 1 / 4; /* First half of the first row */
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .journey-meaning-mission {
    grid-column: 4 / 6; /* Entire second column */
    grid-row: 1 / 3;
  }

  .key-supporters {
    grid-column: 1 / 4; /* First column */
    grid-row: 2 / 3; /* Second row */
    align-self: center; /* Aligns vertically in half row */
  }
  .key-supporters-section-img {
    width: 205px;
    height: 205px;
  }
  .key-supporters-heading {
    font-size: 40px;
  }
  .key-supporters-names {
    font-size: 24px;
  }
  .our-journey-page {
    padding-inline: 0;
  }
}
