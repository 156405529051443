.referral-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral-modal .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 20px !important;
  width: 300px;
  text-align: center;
}

.referral-modal h2 {
  font-weight: bold;
  font-size: 14px;

  margin-top: 12px;
  margin-bottom: 20px;
}

.referral-modal p {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.7;
}

.referral-modal .modal-close-icon {
  width: 8%;
  margin-left: 15rem;
  margin-top: -9px;
}
