.header-container {
  width: 100vw; /* Equivalent to w-screen */
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px; /* Equivalent to px-3 py-4 */
}

.header-container img {
  height: 60px;
}

@media (min-width: 768px) {
  .header-container {
    padding: 16px 32px; /* Equivalent to md:px-8 */
  }
}
