.modal-overlay-comingSoon {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  padding-bottom: 20px;
}

.modal-content-comingSoon {
  background-color: white !important;
  z-index: 60 !important;
  border-radius: 20px;
  padding: 20px;
  padding-top: 80px;
  width: 90%;
  max-width: 500px;
  position: relative;
  display: flex;
  flex-direction: row;
  background-image: url("../../assets/images/bubble\ 1.png"),
    url("../../assets/images/Bubble\ 2.png");
  background-position: right top, left bottom;
  background-repeat: no-repeat;
  background-size: 100px 100px, 80px 80px;
}
.modal-overlay-comingSoon .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding-top: 40px;
}
.modal-overlay-comingSoon .right h1 {
  font-size: 12px;
  color: #0c60ca;
}
.modal-overlay-comingSoon .right h1 span {
  color: #df9b28;
}
.modal-overlay-comingSoon .left {
  flex: 1;
  position: relative;
  background-image: url("../../assets/images/illustration\ BG.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 50px;
  padding-left: 35px;
}
.logo-comingSoon {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-overlay-comingSoon p {
  font-size: 8px;
  text-align: center;
}
.modal-overlay-comingSoon .whatsapp-btn {
  background-color: #0c60ca;
  color: white;
  padding-inline: 8px;
  padding-block: 5px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
