.Congrats-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay background with transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.Congrats-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.Congrats-modal-content p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.Congrats-emoji {
  width: 50px;
  height: 50px;
}

.Congrats-modal-close-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.Congrats-modal-close-btn:hover {
  background-color: #0056b3;
}
